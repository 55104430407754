<template>
  <div class="quotedPriceList">
    <div>
      <span class="mr_20">{{ myShop.name }}</span>
    </div>
    <div class="derive">
      <el-button
        size="small"
        type="success"
        @click="createQuotedPrice()"
        v-if="
          (managerType != 0 || getAuth('quotedPriceOperate')) &&
          infoBusiness.manage_package == 1
        "
        >添加报价</el-button
      >
      <el-button
        size="small"
        v-if="managerType != 0 || getAuth('otherConfiguration')"
        type="success"
        @click="otherConfiguration()"
        >其他配置</el-button
      >
    </div>
    <div class="bill-table">
      <div class="combined">
        <div class="combined-title">非组合还款</div>
        <el-table :data="isnCombinedData" header-cell-class-name="table_header">
          <el-table-column
            property="repay_type_text"
            label="还款方式"
            align="center"
          ></el-table-column>
          <el-table-column
            property="repay_period_numtext"
            label="期限"
            align="center"
          ></el-table-column>
          <el-table-column
            property="platform_fee_rate"
            label="平台管理费率（%）"
            align="center"
          ></el-table-column>
          <el-table-column
            property="platform_fee_pay_assume_text"
            label="平台管理费承担方"
            align="center"
          ></el-table-column>
          <el-table-column
            property="business_fee_extend"
            label="机构管理费"
            align="center"
          ></el-table-column>
          <el-table-column
            property="memo"
            label="备注"
            align="center"
          ></el-table-column>
          <el-table-column label="是否显示" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.is_view == 0">不显示</span>
              <span v-if="scope.row.is_view == 1">显示</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                v-if="
                  (managerType != 0 || getAuth('quotedPriceOperate')) &&
                  infoBusiness.manage_package == 1
                "
                @click="edit(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                v-if="
                  (managerType != 0 || getAuth('quotedPriceOperate')) &&
                  infoBusiness.manage_package == 1
                "
                @click="getDelPackage(scope.row)"
                >删除</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.is_view == 1"
                size="small"
                @click="getNotShow(scope.row)"
                >隐藏</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.is_view == 0"
                size="small"
                @click="getShow(scope.row)"
                >显示</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="combined">
        <div class="combined-title">组合还款</div>
        <el-table :data="combinedData" header-cell-class-name="table_header">
          <el-table-column
            property="repay_type_text"
            label="还款方式"
            align="center"
          ></el-table-column>
          <el-table-column
            property="period_num_text"
            label="期限"
            align="center"
          ></el-table-column>
          <el-table-column
            property="platform_fee_text"
            label="平台管理费率（%）"
            align="center"
          ></el-table-column>
          <el-table-column
            property="platform_fee_pay_text"
            label="平台管理费承担方"
            align="center"
          ></el-table-column>
          <el-table-column
            property="business_fee_extend"
            label="机构管理费"
            align="center"
          ></el-table-column>
          <el-table-column
            property="first_pay_day_text"
            label="首付支付天数"
            align="center"
          ></el-table-column>
          <el-table-column
            property="memo"
            label="备注"
            align="center"
          ></el-table-column>
          <el-table-column label="是否显示" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.is_view == 0">不显示</span>
              <span v-if="scope.row.is_view == 1">显示</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                v-if="
                  (managerType != 0 || getAuth('quotedPriceOperate')) &&
                  infoBusiness.manage_package == 1
                "
                @click="edit(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                v-if="
                  (managerType != 0 || getAuth('quotedPriceOperate')) &&
                  infoBusiness.manage_package == 1
                "
                @click="getDelPackage(scope.row)"
                >删除</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.is_view == 1"
                size="small"
                @click="getNotShow(scope.row)"
                >隐藏</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.is_view == 0"
                size="small"
                @click="getShow(scope.row)"
                >显示</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <quoted-price-modal ref="quotedPriceModal" />
    <other-configuration ref="otherConfiguration" />
  </div>
</template>

<script>
import { suggestBusinessList, infoBusiness } from "@/api/business.js";
import { getMyShop, getAuth } from "@/utils/index.js";
import quotedPriceModal from "./components/quoted-price-modal.vue";
import otherConfiguration from "./components/other-configuration.vue";
import { listPackage, viewPackage, delPackage } from "@/api/package.js";
export default {
  name: "quotedPriceList",
  components: { quotedPriceModal, otherConfiguration },
  data() {
    return {
      managerType: localStorage.getItem("__managerType__"),
      getAuth: getAuth,
      myShop: getMyShop(),
      infoBusiness: {},
      searchForm: {
        page: 1,
        per_page: 10,
      },
      loading: false,
      total: 0,
      combinedData: [],
      isnCombinedData: [],
      businessLoading: false,
      businessList: [],
      baseBusinessList: [],
      payTypeList: {
        1: "等额本金",
        2: "组合还款",
      },
      isnPayTypeList: {
        1: "等额本金",
        3: "先息后本",
      },
      discountFeeList: {
        0: "否",
        1: "是",
      },
      platformFeePayList: {
        1: "学员",
        2: "机构",
      },
    };
  },

  async mounted() {
    this.initData();
    this.baseBusinessList = await this.getSelectBusiness();
  },

  methods: {
    async initData() {
      this.getRepaymentList();
    },
    async getRepaymentList() {
      this.searchForm.businessId = this.myShop.id;
      await this.getIsnCombine(this.searchForm);
      await this.getCombined(this.searchForm);
      await this.getInfoBusiness(this.searchForm.businessId);
    },
    async getInfoBusiness(businessId) {
      const param = {
        businessId: businessId,
      };
      const { code, data } = await infoBusiness(param);
      if (code == 200) {
        this.infoBusiness = data;
      }
    },
    async getIsnCombine(param) {
      var that = this;
      param.repay_type = 1;
      const { code, data } = await listPackage(param);
      if (code == 200) {
        this.isnCombinedData = data.map((item) => {
          item.repay_type_text = that.payTypeList[item.repay_type];
          item.repay_period_numtext = item.repay_period_num + "月";
          item.platform_fee_pay_assume_text =
            that.platformFeePayList[item.platform_fee_pay_assume];
          return item;
        });
      }
    },
    async getCombined(param) {
      var that = this;
      param.repay_type = 2;
      const { code, data } = await listPackage(param);
      if (code == 200) {
        this.combinedData = data.map((item) => {
          item.repay_type_text =
            "X:" +
            that.isnPayTypeList[item.x_repay_type] +
            "；Y:" +
            that.isnPayTypeList[item.y_repay_type];
          item.period_num_text =
            "X:" + item.x_period_num + "月；" + "Y:" + item.y_period_num + "月";
          item.platform_fee_text =
            "X:" + item.x_platform_fee_rate + "；Y:" + item.x_platform_fee_rate;
          item.platform_fee_pay_text =
            "X:" +
            that.platformFeePayList[item.x_platform_fee_repay_assume] +
            "；Y:" +
            that.platformFeePayList[item.y_platform_fee_repay_assume];
          if (Number(item.first_repay_day) == 0) {
            item.first_pay_day_text = "一个月";
          } else {
            item.first_pay_day_text = item.first_repay_day + "天后";
          }
          return item;
        });
      }
    },
    createQuotedPrice() {
      if (this.searchForm.businessId) {
        this.$refs.quotedPriceModal.isShow(
          "add",
          "",
          this.searchForm.businessId,
          this.infoBusiness
        );
      } else {
        this.$message({
          message: "请选择商家",
          type: "warning",
        });
      }
    },
    edit(row) {
      this.$refs.quotedPriceModal.isShow(
        "edit",
        row,
        this.searchForm.businessId,
        this.infoBusiness
      );
    },
    otherConfiguration() {
      if (this.searchForm.businessId) {
        this.$refs.otherConfiguration.isShow(
          this.searchForm.businessId,
          this.infoBusiness.exceed_fee_day
        );
      } else {
        this.$message({
          message: "请选择商家",
          type: "warning",
        });
      }
    },
    getDelPackage(row) {
      this.$confirm("是否删除当前报价？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const parmes = {
            package_id: row.package_id,
          };
          const { code } = await delPackage(parmes);
          if (code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.initData();
          }
        })
        .catch(() => {});
    },
    async getNotShow(row) {
      this.$confirm("是否不在显示当前报价？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const param = {
            package_id: row.package_id,
            is_view: 0,
          };
          this.getViewPackage(param);
        })
        .catch(() => {});
    },
    async getShow(row) {
      this.$confirm("是否显示当前报价？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const param = {
            package_id: row.package_id,
            is_view: 1,
          };
          this.getViewPackage(param);
        })
        .catch(() => {});
    },
    async getViewPackage(param) {
      const { code } = await viewPackage(param);
      if (code == 200) {
        this.initData();
        this.$message({
          type: "success",
          message: "操作成功",
        });
      }
    },
    businessRemoteMethod(query) {
      if (query) {
        this.businessLoading = true;
        setTimeout(async () => {
          this.businessLoading = false;
          this.businessList = await this.getSelectBusiness(query);
        }, 200);
      } else {
        this.businessList = this.baseBusinessList;
      }
    },
    async getSelectBusiness(keyword) {
      const { code, data } = await suggestBusinessList({
        keyword: keyword || "",
      });
      if (code == 200) {
        return data;
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.quotedPriceList {
  .derive {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .bill-table {
    .combined {
      margin-bottom: 20px;
      border: 1px solid #ebeef5;
      border-bottom: 0px;
      .combined-title {
        padding: 10px 20px;
        border-bottom: 1px solid #ebeef5;
      }
      /deep/ .table_header {
        background-color: #f8f8f9 !important;
        color: #515a6e !important;
        font-size: 10px;
        .cell {
          padding: 0px 5px;
        }
      }
    }
  }
}
</style>