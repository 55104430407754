import request from '@/utils/request'

// 商家列表/suggest
export function suggestBusinessList(params) {
  return request.post('api/businessList', params)
}

// 商家详情-包括商家权限信息
export function infoBusiness(params) {
  return request.post('api/infoBusiness', params)
}

