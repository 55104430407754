<template>
  <div>
    <el-dialog
      title="其他配置"
      width="770px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form :model="form" size="small">
        <div v-if="exceed_fee_day == 1">
          <div class="sub-title">逾期费用项</div>
          <div class="classification">
            <el-form-item label="逾期宽限期：" label-width="110px">
              <div class="flex">
                <el-select
                  class="item-value"
                  v-model="form.exceed_fee_day"
                  placeholder="请选择天数"
                >
                  <el-option
                    v-for="item in gracePeriodList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <span class="ml_10">天</span>
              </div>
            </el-form-item>
          </div>
        </div>
        <div v-else class="empty">
          <div>暂无其他配置</div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="confirm()"
          size="small"
          v-if="exceed_fee_day == 1"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
 
<script>
import { gracePeriodList } from "@/assets/equipment/allSelect.json";
import { infoOtherPackage, editOtherPackage } from "@/api/package.js";
export default {
  name: "other-configuration",
  data() {
    return {
      dialogFormVisible: false,
      gracePeriodList: gracePeriodList,
      form: {
        single_pay_type: 1,
        x_pay_type: 1,
        y_pay_type: 1,
        exceed_fee_type: 1,
        late_fee_type: 2,
      },
      exceed_fee_day: 0,
    };
  },
  methods: {
    async isShow(row, exceed_fee_day) {
      this.dialogFormVisible = true;
      this.exceed_fee_day = exceed_fee_day;
      const parme = {
        business_id: row,
      };
      console.log(parme);
      const { code, data } = await infoOtherPackage(parme);
      if (code == 200) {
        this.form = data;
      }
    },
    async confirm() {
      this.form.single_pay_type = 1
      this.form.x_pay_type = 1
      this.form.y_pay_type = 1
      this.form.exceed_fee_type = 1
      this.form.late_fee_type = 2
      const { code } = await editOtherPackage(this.form);
      if (code == 200) {
        this.close();
        this.$message.success("操作成功");
        this.$parent.initData();
      }
    },
    close() {
      this.dialogFormVisible = false;
      this.form = {
        single_pay_type: 1,
        x_pay_type: 1,
        y_pay_type: 1,
        exceed_fee_type: 1,
        late_fee_type: 2,
      };
    },
    handleClose(done) {
      done();
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .customW {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .empty {
    display: flex;
    justify-content: center;
  }
  .sub-title {
    background: #eee;
    padding: 8px 20px;
  }
  .classification {
    margin: 10px 20px;
    .content-form {
      margin: 0px 10px;
    }
    .item-value {
      width: 120px;
      max-width: 120px;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>
